import React, { useCallback, useEffect, useState } from 'react';
import cls from 'classnames';

// import leaveIcon from '@public/imgs/leave-icon.svg';
// import moreIcon from '@public/imgs/more-icon.svg';
// import normalIcon from '@public/imgs/normal-icon.gif';
import { useTranslation } from 'next-i18next';
import useDebounce from '@/utils/hooks/useDebounce';
import { Modal, Toast } from '@/components';
import Button from '@/components/NewButton';
import { IApiModal, IModalInfoParams } from '@/components/Modal/interface';
import useCountDown from '@/utils/hooks/useCountDown';
import styles from './style.module.scss';
import { ClassTypeEnum, StatusEnum, SubjectTypeEnum } from '../../Home/interface';
// import ClassGuide from '@/pageComponents/Home/ClassGuide';
// import { CLASS_GUIDE_FLAG } from '@/utils/constants';
import { log } from '@/api/log';
import useUser from '@/store/useUser';
import ParentalVerification from '@/pageComponents/Autonomy/ClassPreparation/ParentalVerification';

export interface CountDownProps {
  subject?: keyof typeof SubjectTypeEnum;
  status: string;
  timeOffset: number;
  onEnd(disable: boolean): void;
  onLeave(): void;
  classType: keyof typeof ClassTypeEnum;
  // courseType: keyof typeof CourseTypeEnum;
  platform?: string;
  showLeaveButton?: boolean;
  onClickGuide?: () => void;
}

const DEFAULT_ENTER_CLASS_SECOND = 11 * 60;

const DEFAULT_ENTER_TALK_CLOUD_CLASS_SECOND = 20 * 60;

// 学科字体颜色class
// const SubjectTypeClassName = {
//   [SubjectTypeEnum.MATH]: 'font-color-blue',
//   [SubjectTypeEnum.CHINESE]: 'font-color-pink',
//   [SubjectTypeEnum.ENGLISH]: 'font-color-green',
// };

const Status = (props: CountDownProps) => {
  const {
    // subject = SubjectTypeEnum.CHINESE,
    status,
    classType,
    timeOffset,
    onEnd,
    onLeave,
    // courseType,
    // onClickGuide,
    showLeaveButton,
    platform,
  } = props;
  const [countTime, countHour] = useCountDown(timeOffset);
  const [showVerificationModal, setShowVerificationModal] = useState<boolean>(false);
  // const { t, i18n } = useTranslation('curriculum');
  const { t: homeTranslation } = useTranslation('home');
  const { isCompletedParentalVerification } = useUser();
  // const [guideVis, setGuideVis] = useState(false);
  // const [classVisible, setClassVisible] = useState(false);

  // const handleJoinGuide = () => {
  //   onClickGuide?.();
  //   setClassVisible(true);
  //   setGuideVis(false);
  //   window.localStorage.setItem(CLASS_GUIDE_FLAG, '1');
  // };

  // useEffect(() => {
  //   const guideFlag = window.localStorage.getItem(CLASS_GUIDE_FLAG);
  //   // setGuideVis(!guideFlag);
  // }, []);

  const handleGroupLesson = useCallback(() => {
    if (countHour >= 48) {
      onLeave();
    } else Toast.warning(homeTranslation('不允许调课'));
  }, [countHour]);

  const handlePrivateLesson = useCallback(() => {
    if (countHour >= 2) {
      onLeave();
    } else if (countHour < 2 && countTime > 0) {
      let modal: IApiModal<IModalInfoParams> | null = null;
      modal = Modal.info({
        content: (
          <div className={styles.modalContent}>
            <div className={styles.modalTitle}>{homeTranslation('紧急请假提示')}</div>
            <div
              className={styles.modalTip}
              dangerouslySetInnerHTML={{
                __html: homeTranslation('紧急请假说明'),
              }}
            />
            <div className={styles.modalSubTip}>
              <span className={styles.tip}>{homeTranslation('紧急请假温馨提示')}</span>
            </div>
            <div className={styles.btnGroup}>
              <Button
                type="cancel"
                onClick={() => {
                  modal?.close();
                  log('curriculum.leaveApply.cancel');
                }}
                className={cls(styles.cancel)}
                text={homeTranslation('我在想想')}
              />
              <Button
                type="red"
                onClick={() => {
                  modal?.close();
                  onLeave();
                }}
                className={cls(styles.submit)}
                text={homeTranslation('确定请假')}
              />
            </div>
          </div>
        ),
        closeBtn: false,
        className: styles.alert,
      });
    }
  }, [countHour]);

  const handleCheckLeave = useCallback(() => {
    if (isCompletedParentalVerification) {
      setShowVerificationModal(true);
      return;
    }
    switch (classType) {
      case ClassTypeEnum.PRIVATE:
        handlePrivateLesson();
        break;
      case ClassTypeEnum.GROUP:
        handleGroupLesson();
        break;
      default:
        break;
    }
  }, [countTime, classType]);

  const handleOnLeave = useDebounce({ func: handleCheckLeave, wait: 800 });

  useEffect(() => {
    const enterClassTime =
      platform === 'TALK_CLOUD' ? DEFAULT_ENTER_TALK_CLOUD_CLASS_SECOND : DEFAULT_ENTER_CLASS_SECOND;
    if ((countTime === 0 || countTime < enterClassTime) && status === StatusEnum.NORMAL) {
      onEnd(true);
    }
  }, [countTime, status]);

  // const getLessonNode = useMemo(() => {
  //   // 产品要求不再展示如何上课
  //   // const guideNode =
  //   //   courseType === 'TRIAL' && platform === 'CLASS_IN' ? (
  //   //     <div className={styles.joinGuide} role="button" onClick={handleJoinGuide}>
  //   //       <span>{t('如何上课')}</span>

  //   //       {guideVis && (
  //   //         <img
  //   //           src={i18n?.language === 'zh' ? '/imgs/pc/home_lesson_guide_zh.png' : '/imgs/pc/home_lesson_guide_en.png'}
  //   //           alt=""
  //   //         />
  //   //       )}
  //   //     </div>
  //   //   ) : (
  //   //     ''
  //   //   );

  //   // 产品要求以下逻辑不再展示
  //   // if (countTime < 60) {
  //   //   switch (status) {
  //   //     case StatusEnum.ABSENT:
  //   //       return <span className={styles.absenter}>{t('缺席')}</span>;
  //   //     case StatusEnum.COMPLETED:
  //   //       return <span className={styles.completed}>{t('已结束')}</span>;
  //   //     case StatusEnum.NORMAL:
  //   //       return (
  //   //         <>
  //   //           {guideNode}
  //   //           <div className={styles.normal}>
  //   //             <img src={normalIcon.src} alt="" />
  //   //             {t('上课中')}
  //   //           </div>
  //   //         </>
  //   //       );
  //   //     default:
  //   //       return '';
  //   //   }
  //   // } else if (countHour < 24) {
  //   //   return (
  //   //     <div className={styles.countdown}>
  //   //       <p
  //   //         dangerouslySetInnerHTML={{
  //   //           __html: t(`倒计时-${countHour > 0 ? '时' : '分'}`, {
  //   //             小时: `<span class=${styles[SubjectTypeClassName[subject]]}> ${countHour} </span>`,
  //   //             分钟: `<span class=${styles[SubjectTypeClassName[subject]]}> ${countMinute} </span>`,
  //   //           }),
  //   //         }}
  //   //       />
  //   //       {guideNode}
  //   //     </div>
  //   //   );
  //   // }
  //   return guideNode;
  // }, [status, countTime, countHour, countMinute]);

  return (
    <>
      {/* <ClassGuide visible={classVisible} onChangeVisible={(value) => setClassVisible(value)} /> */}
      <div className={styles.statusWrapper}>
        {/* {getLessonNode} */}
        {showLeaveButton && (
          // <div className={styles.moreBox}>
          //   <img src={moreIcon.src} alt="" />
          // <div className={styles.moreWrapper}>
          <div className={styles.moreItem} role="button" onClick={handleOnLeave}>
            {/* <img src={leaveIcon.src} alt="" /> */}
            <span>{homeTranslation(classType === ClassTypeEnum.PRIVATE ? '请假' : '调课')}</span>
          </div>
          // </div>
          // </div>
        )}
        <ParentalVerification
          open={showVerificationModal}
          onClose={() => {
            setShowVerificationModal(false);
          }}
        />
      </div>
    </>
  );
};

export default Status;
