import React from 'react';
import { Modal } from '@/components';
import classNames from 'classnames';
import Image from 'next/image';
import WukongLookImg from 'public/imgs/pc/classroom-wukong-look.png';

import styles from './styles.module.scss';

const ToolTipModal = () => {};

ToolTipModal.open = (t: any, sureCallback: () => void) => {
  const modalInstance = Modal.info({
    content: (
      <div className={styles.infoContent}>
        <div className={styles.headerImg}>
          <Image src={WukongLookImg} alt="WukongLookImg" layout="fill" />
        </div>
        <div className={styles.description}>{t('您已在其他设备进入教室，本次进入将踢出其他设备，是否继续？')}</div>
        <div className={styles.footer}>
          <div onClick={() => modalInstance.close()} className={classNames(styles.btn, styles.cancelBtn)}>
            {t('取消')}
          </div>
          <div
            onClick={() => {
              sureCallback();
              modalInstance.close();
            }}
            className={classNames(styles.btn, styles.sureBtn)}
          >
            {t('进入教室')}
          </div>
        </div>
      </div>
    ),
    closeBtn: false,
    className: styles.toolTipModal,
  });
};

export default ToolTipModal;
