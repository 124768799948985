import styles from './style.module.scss';
import Image from 'next/image';
import Reschedule, { IRescheduleModal } from '../RescheduleModal';
import { useContext, useEffect, useMemo, useRef } from 'react';
import processingIcon from '@public/imgs/pc/processing-icon.gif';
import _ from 'lodash';
import cls from 'classnames';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import {
  STUDENT_COURSE_ABSENT,
  STUDENT_COURSE_COMPLETED,
  STUDENT_COURSE_NORMAL,
  TRIAL_CARD_JOIN_CLICK,
  TRIAL_CARD_RESCHEDULE_CLICK,
} from '@/pageComponents/Home/TrialCourse/constants';
import { useTranslation } from 'next-i18next';
import { GS2_STU_PC, GS2_TEACHER_LIST, goGs2Booking } from '@/utils/gs2';
import { Toast } from '@/components';
import { AGORA_URL } from '@/utils/env';
import { forbiddenBrowser, isTablet, mainDomainTokenSet } from '@/utils/utils';
import { wakeUpWukong } from '@/utils/wakeUp';
import { removeStorage } from '@/pageComponents/DeviceDetect/utils';
import parseJWT from '@/utils/parseJWT';
import { getTalkCloudLink } from '@/api/home';
import useUser from '@/store/useUser';
import { goWebSite } from '@/utils/goWebSite';
import { IMemoReportProps } from '@/pageComponents/Home/TrialCourse/interface';
import { trace, traceEvent } from '@wk/wk-gatherer';
import TrialTraceDataContext from '@/pageComponents/Home/TrialCourse/TrialTraceDataContext';
type IProps = {
  canEnter?: boolean;
};

const JoinClass = ({
  canEnter,
  platform,
  courseScheduleId,
  courseSubject,
  status = STUDENT_COURSE_NORMAL,
}: Partial<ICourseInfo> & IMemoReportProps & IProps) => {
  const { t, i18n } = useTranslation('trailCourse');
  const { t: homeT } = useTranslation('home');
  const { curStudent } = useUser();
  const { isGs2User } = useUser((store) => ({
    isGs2User: store.isGs2User,
  }));
  const isZh = i18n.language === 'zh';
  const rescheduleRef = useRef<IRescheduleModal>();
  const traceData = useContext(TrialTraceDataContext);
  // 置灰: 已完课
  const disableBtn = status === STUDENT_COURSE_COMPLETED;
  // 待开课 & >开课前20分钟
  const beforeClass = !!(!canEnter && status === STUDENT_COURSE_NORMAL);
  // 可加入课程
  const canEnterClass = canEnter && status === STUDENT_COURSE_NORMAL;
  // 缺勤可重新预约
  const reSchedule = status === STUDENT_COURSE_ABSENT;

  const handleEnterClassRoom = async () => {
    if (canEnter) {
      if (platform === 'AGORA') {
        mainDomainTokenSet('CLASSROOM_TOKEN');
        window.open(`${AGORA_URL}/${i18n.language}/student?courseScheduleId=${courseScheduleId}`);
      } else if (platform === 'TALK_CLOUD') {
        if (isTablet()) {
          wakeUpWukong();
          return;
        }
        if (forbiddenBrowser()) {
          Toast.warning(t('课中直播不支持使用火狐、IE或者Opera浏览器，建议使用Chrome浏览器去上课。'));
          return;
        }

        removeStorage(i18n.language);
        // get TalkCloud url
        const target = window.open('about:blank');
        const jwt = parseJWT();
        const name =
          courseSubject === 'CHINESE'
            ? curStudent?.fullName
              ? curStudent.fullName || ''
              : curStudent?.englishName || ''
            : curStudent?.englishName
              ? curStudent.englishName || ''
              : curStudent?.fullName || '';
        getTalkCloudLink({
          courseScheduleId: courseScheduleId as string,
          clientType: 'WEB',
          userType: 'STUDENT',
          uuid: curStudent?.uuid || jwt.data.studentId,
          name: name?.trim() || 'Student',
          language: i18n.language === 'en' ? 'EN_US' : 'ZH_CN',
          norecord: curStudent && !curStudent.setting.playbackPrivacyStatus,
          trialStudent: courseSubject === 'MATH' && true,
        })
          .then((e) => {
            if (e) {
              target!.location.href = e;
            } else {
              target?.close?.();
            }
          })
          .catch((error) => {
            trace('LOG_ERROR_GET_TALK_CLOUD_LINK', {
              error,
            });
            target?.close?.();
          });
      }
    }
  };

  const routeToJoin = () => {
    traceEvent({
      data: {
        _event: TRIAL_CARD_JOIN_CLICK,
        ..._.pick(traceData, [
          'courseScheduleId',
          'courseType',
          'subject',
          'courseStatus',
          'preparationStatus',
          'previewStatus',
        ]),
        joinType: '学员',
      },
    });
    if (!canEnter) {
      return Toast.warning(homeT('自主化-课前20分钟可进入教室', { time: homeT('自主化-课前时间') }));
    }
    handleEnterClassRoom?.();
  };
  // 去重新预约
  const routerToReschedule = () => {
    traceEvent({
      data: {
        _event: TRIAL_CARD_RESCHEDULE_CLICK,
        ..._.pick(traceData, ['courseScheduleId', 'courseType', 'subject', 'courseStatus']),
      },
    });
    isGs2User
      ? goGs2Booking({ source: GS2_STU_PC, targetStep: GS2_TEACHER_LIST, trigger_name: TRIAL_CARD_RESCHEDULE_CLICK })
      : goWebSite(`independent-appointment?subject=${courseSubject?.toLowerCase()}`);
  };

  useEffect(() => {
    status === STUDENT_COURSE_ABSENT && rescheduleRef?.current?.onShow();
    return () => {
      rescheduleRef?.current?.onHide();
    };
  }, []);

  const btnStatus = useMemo(() => {
    let text = t('按钮-进教室');
    let clickFunc;
    if (status === STUDENT_COURSE_COMPLETED) {
      text = t('按钮-已完成');
      clickFunc = () => {};
    } else if (status === STUDENT_COURSE_ABSENT) {
      text = t('取消课程-再次预约');
      clickFunc = routerToReschedule;
    } else {
      clickFunc = routeToJoin;
    }
    return {
      text,
      clickFunc,
    };
  }, [traceData, routerToReschedule, routeToJoin]);

  useEffect(() => {
    status === STUDENT_COURSE_ABSENT && rescheduleRef?.current?.onShow();
    return () => {
      rescheduleRef?.current?.onHide();
    };
  }, []);

  return (
    <div className={styles.joinClassOperate}>
      <ul className={styles.descList}>
        <li
          className={styles.desc}
          dangerouslySetInnerHTML={{
            __html: t('课前准备-描述2', {
              mins: `<span class=${styles.time}>20${isZh ? '' : ' '}${t('分钟')}</span>`,
            }),
          }}
        />
        <li className={styles.desc}>{t('课前准备-描述1')}</li>
      </ul>
      <div className={styles.btnBox}>
        <div
          className={cls(
            styles.step1Btn,
            (canEnterClass || reSchedule) && styles.step1BtnActive,
            beforeClass && styles.step1BtnBefore,
            disableBtn && styles.step1BtnDisable,
          )}
          onClick={btnStatus.clickFunc}
        >
          {canEnterClass && (
            <div className={styles.classing}>
              <Image width={12} src={processingIcon} height={12} alt="" />
            </div>
          )}
          {btnStatus.text}
        </div>
        <Reschedule ref={rescheduleRef} />
      </div>
    </div>
  );
};
export default JoinClass;
