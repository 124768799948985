/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import cls from 'classnames';
import videojs from 'video.js';
import { CustomModal } from '@/components';
import 'videojs-contrib-hls';
import 'video.js/dist/video-js.css';

import styles from './style.module.scss';

export interface VideoProps {
  src: string;
  visible: boolean;
  onClose: () => void;
}

const M3u8Video = (props: VideoProps) => {
  const { visible, src, onClose } = props;
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef?.current && visible) {
      videojs(videoRef?.current ?? '', {
        autoplay: 'muted',
        controls: true,
        muted: false,
        bigPlayButton: true,
        // @ts-ignore
        posterImage: false,
        errorDisplay: false,
        hls: {
          withCredentials: true,
        },
      });
    }
  }, [visible]);

  return (
    <CustomModal className={styles.modal} bodyClassName={styles.modalBody} visible={visible} onClose={onClose}>
      <video className={cls(styles.video, 'video-js', 'vjs-default-skin')} ref={videoRef} preload="auto" muted>
        <source src={src} type="application/x-mpegURL" />
      </video>
    </CustomModal>
  );
};

export default M3u8Video;
