import { useEffect, useState } from 'react';
import Collapse from '../Collapse';
import StepNumber from '../StepNumber';
import { HOMEWORK, TASK_COMPLETED, TASK_NORMAL, TASK_PROCESSING } from '../constants';
import { ITaskState } from '../interface';
import styles from './style.module.scss';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import { useTranslation } from 'next-i18next';
import Step from './components/Step';

type IProps = {
  step: number;
  course?: Partial<ICourseInfo>;
};
const Homework = ({ course, step }: IProps) => {
  const { t } = useTranslation(['trailCourse']);
  const { homeworkCompleted, homeworkPublish } = course || {};
  const [taskState, setTaskState] = useState<ITaskState>(TASK_NORMAL);

  const handleExpand = (expand: boolean) => {
    if (homeworkCompleted) {
      // 节点二已完成(绿色打钩)状态: 作业完跟用户点击展开收起无关)
      setTaskState(TASK_COMPLETED);
    } else {
      // 作业未完成: 展开: 粉色 收起: 灰色
      setTaskState(expand ? TASK_PROCESSING : TASK_NORMAL);
    }
  };

  useEffect(() => {
    setTaskState(homeworkCompleted ? TASK_COMPLETED : TASK_NORMAL);
  }, [homeworkCompleted]);

  return (
    <>
      <StepNumber taskState={taskState} step={step} isPlot={homeworkPublish && !homeworkCompleted} />
      <Collapse
        onCollapse={handleExpand}
        defaultExpand={true}
        title={<span className={styles.stepBarTitle}>{t('完成课后作业')}</span>}
      >
        <Step {...course} key={HOMEWORK} />
      </Collapse>
    </>
  );
};

export default Homework;
