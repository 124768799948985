import { useContext } from 'react';
import qs from 'query-string';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import styles from './style.module.scss';
import cls from 'classnames';
import { HOMEWORK_URL } from '@/utils/env';
import { useRouter } from 'next/router';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import { mainDomainTokenSet } from '@/utils/utils';
import { traceEvent } from '@wk/wk-gatherer';
import { TRIAL_CARD_TO_HOMEWORK_CLICK } from '@/pageComponents/Home/TrialCourse/constants';
import TrialTraceDataContext from '@/pageComponents/Home/TrialCourse/TrialTraceDataContext';

const TrialStep2 = ({ homeworkCompleted, courseSectionId, courseScheduleId }: Partial<ICourseInfo>) => {
  const { t, i18n } = useTranslation(['trailCourse']);
  const traceData = useContext(TrialTraceDataContext);
  const router = useRouter();
  const routeToHomework = () => {
    traceEvent({
      data: {
        _event: TRIAL_CARD_TO_HOMEWORK_CLICK,
        ..._.pick(traceData, ['courseScheduleId', 'courseType', 'subject', 'courseStatus']),
      },
    });
    mainDomainTokenSet();
    const url = `${HOMEWORK_URL()}/${i18n.language}?${qs.stringify({
      courseSectionId,
      courseScheduleId,
      applyType: 'AFTER_CLASS',
    })}`;
    router.push({
      pathname: `/home/iexercise`,
      query: { url, backUrl: router.asPath },
    });
  };
  return (
    <div className={styles.homeworkOperate}>
      <div className={styles.checkStep}>
        <div className={styles.tip}>{t('完成课后作业-描述')}</div>
      </div>
      <div
        className={cls(styles.step2Btn, homeworkCompleted ? styles.step2BtnDisable : styles.step2BtnActive)}
        onClick={routeToHomework}
      >
        {homeworkCompleted ? t('按钮-已完成') : t('按钮-去完成')}
      </div>
    </div>
  );
};

export default TrialStep2;
