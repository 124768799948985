import cls from 'classnames';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';

import useUser from '@/store/useUser';
import { getPackages } from '@/api/order';
import { traceEvent } from '@wk/wk-gatherer';
import { WKModal } from '@wk/components';
import { getTokenInfo } from '@/utils/parseToken';

import style from './style.module.scss';

// border渐变组件
const GradientBorder = ({
  children,
  className,
  activeClass,
  hoverClass,
}: React.PropsWithChildren<{ className: string; activeClass: string; hoverClass?: string }>) => {
  return (
    <div className={cls(style['gradient-border'], activeClass, hoverClass)}>
      <p className={className}>{children}</p>
    </div>
  );
};

const SubscriptionCourse = () => {
  const router = useRouter();
  const [isEsUser, setIsEsUser] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [curSubject, setCurSubject] = useState<any>();
  const [subjectOptions, setSubjectOptions] = useState<{ class: string; name: string; key: string }[]>([]);
  const { curStudent } = useUser();

  const { t } = useTranslation('order');

  const subjects = [
    {
      class: 'chinese',
      name: t('订阅-国际中文'),
      key: 'CHINESE',
    },
    {
      class: 'math',
      name: t('订阅-国际数学'),
      key: 'MATH',
    },
    {
      class: 'english',
      name: t('订阅-英文阅读写作'),
      key: 'ENGLISH',
    },
  ];

  const fetchData = async () => {
    const data = await getPackages();
    const subjectOptionsVo = _.intersectionWith(
      subjects,
      Object.keys(data.content?.eduPlanInfoRespMap || {}),
      (a, b) => a.key === b,
    );
    setSubjectOptions(subjectOptionsVo);
    setCurSubject(subjectOptionsVo?.[0]);
    setIsEsUser(data?.content?.isEsUser);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isEsUser) {
      const info = getTokenInfo();
      const data = {
        _event: 'U_HomePage_SubscriptionExpose',
        code: info?.data.code,
        accountId: info?.sub,
      };
      traceEvent({
        data,
      });
    }
  }, [isEsUser]);

  if (!isEsUser) {
    return null;
  }

  return (
    <div className={style.subscriptionCourse}>
      {/* <div className={cls(style.title, style.boldTitle)}>{t('订阅课包-精选课程')}</div> */}
      <div className={style.wrap}>
        <div className={style.container}>
          <div className={style.subscription}>
            <img src={'/imgs/Rectangle 34625398.png'} alt="" className={style.bg} />
            <div className={style.subscriptionLeft}>
              <div>
                <h2 dangerouslySetInnerHTML={{ __html: t('订阅课包-订阅你的课程') }}></h2>
                <h4>{t('订阅副标题')}</h4>
                {/* <div className={style.tags}>
                  <span>{t('订阅课包-国际中文')}</span>
                <span>{t('订阅课包-国际数学')}</span>
                <span>{t('订阅课包-英文阅读写作')}</span>
                </div> */}
              </div>
              <div className={style.subscribeButtonWrap}>
                {/* <img src="/imgs/shine.svg" alt="" /> */}
                <button
                  onClick={() => {
                    traceEvent({
                      data: {
                        _event: 'U_HomePage_Subscription_ViewDetailsClick',
                        Code: curStudent?.code,
                      },
                    });
                    // only one subject
                    if (subjectOptions.length === 1 && curSubject) {
                      router.push(`/home/subscription/plan/1?subject=${curSubject.key}`);
                      return;
                    }
                    setOpen(true);
                  }}
                >
                  <span>{t('订阅课包-查看详情')}</span>
                </button>
                {/* <img style={{ transform: 'rotate(180deg)' }} src="/imgs/shine.svg" alt="" />
                <img className={style.hand} src="/imgs/hand.svg" alt="" /> */}
              </div>
            </div>
            {/* <div className={style.subscriptionRight}>
              <img src="/imgs/coursePackage/stu1.png" alt="" />
              <img src="/imgs/coursePackage/stu2.png" alt="" />
              <img src="/imgs/coursePackage/stu3.png" alt="" />
            </div> */}
          </div>
        </div>
      </div>
      <WKModal open={open} radius={16} showClose={false}>
        <div className={style.modal}>
          <img
            className={style.closeIcon}
            src="/imgs/coursePackage/icon_close.svg"
            alt=""
            onClick={() => {
              traceEvent({
                data: {
                  _event: 'U_HomePage_SubscriptionSubjectPop_CloseClick',
                  subject: curSubject,
                  Code: curStudent?.code,
                },
              });
              setOpen(false);
            }}
          />
          <h2>{t('订阅-选择科目')}</h2>
          <ul>
            {subjectOptions.map((subject, key) => {
              return (
                <li
                  key={key}
                  className={cls({
                    // [style[subject.class]]: true,
                    // [style[`${subject.class}-active`]]: curSubject?.key === subject.key
                  })}
                  onClick={() => {
                    traceEvent({
                      data: {
                        _event: 'U_HomePage_SubscriptionSubjectPop_SubjectClick',
                        subject: subject.class,
                        Code: curStudent?.code,
                      },
                    });
                    setCurSubject(subject);
                  }}
                >
                  <GradientBorder
                    className={style[subject.class]}
                    activeClass={curSubject?.key === subject.key ? style[`${subject.class}-active`] : ''}
                  >
                    {subject.name}
                  </GradientBorder>
                </li>
              );
            })}
          </ul>
          <button
            onClick={() => {
              traceEvent({
                data: {
                  _event: 'U_HomePage_SubscriptionSubjectPop_ConfirmClick',
                  subject: curSubject,
                  Code: curStudent?.code,
                },
              });
              if (curSubject) {
                router.push(`/home/subscription/plan/1?subject=${curSubject.key}`);
              }
            }}
          >
            {t('订阅-确认选择科目')}
          </button>
        </div>
      </WKModal>
    </div>
  );
};

export default SubscriptionCourse;
