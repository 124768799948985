import React from 'react';

const Book = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M16.1451 3.68165C14.8851 3.74026 12.8614 3.96412 11.2231 4.7506C10.8246 4.94216 10.3881 5.04163 9.94586 5.04163C9.50367 5.04163 9.06715 4.94216 8.66861 4.7506C7.03049 3.96412 5.00728 3.74026 3.74663 3.68165C3.59206 3.67438 3.43761 3.69855 3.29264 3.75269C3.14768 3.80682 3.0152 3.88981 2.90322 3.99662C2.79125 4.10343 2.70211 4.23185 2.6412 4.3741C2.58028 4.51635 2.54886 4.66948 2.54883 4.82423V13.7289C2.54876 14.0498 2.67546 14.3578 2.90134 14.5857C3.12722 14.8137 3.43399 14.9431 3.75488 14.946C7.27635 14.9752 8.778 16.334 9.94618 16.334C11.1628 16.334 12.6158 14.9752 16.1373 14.946C16.4582 14.9431 16.7649 14.8137 16.9908 14.5857C17.2167 14.3578 17.3434 14.0498 17.3433 13.7289V4.82423C17.3433 4.66944 17.3119 4.51625 17.251 4.37396C17.19 4.23166 17.1009 4.10321 16.9888 3.99639C16.8768 3.88957 16.7443 3.80659 16.5992 3.75249C16.4542 3.69839 16.2997 3.67429 16.1451 3.68165Z"
        fill="url(#paint0_linear_1668_27456)"
        fillOpacity="0.8"
      />
      <path
        d="M13.8596 9.11457C13.6665 9.1146 13.4752 9.07657 13.2967 9.00265C13.1182 8.92874 12.956 8.82039 12.8194 8.6838C12.6828 8.5472 12.5745 8.38503 12.5006 8.20655C12.4267 8.02807 12.3886 7.83678 12.3887 7.6436V6.05394C12.3886 5.95212 12.4185 5.85253 12.4745 5.7675C12.5305 5.68247 12.6102 5.61573 12.7037 5.57554C13.0763 5.41767 13.4635 5.2967 13.8596 5.21436C14.2142 5.13949 14.5744 5.09441 14.9364 5.07957C14.9874 5.07758 15.0383 5.08591 15.086 5.10405C15.1338 5.12219 15.1773 5.14979 15.2141 5.18517C15.251 5.22055 15.2802 5.263 15.3002 5.30997C15.3203 5.35695 15.3306 5.40747 15.3306 5.45853V7.6436C15.3306 8.03373 15.1756 8.40787 14.8998 8.68373C14.6239 8.95959 14.2498 9.11457 13.8596 9.11457Z"
        fill="url(#paint1_linear_1668_27456)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1668_27456"
          x1="3.03643"
          y1="3.4223"
          x2="16.2892"
          y2="15.3071"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.190651" stopColor="#FF5353" />
          <stop offset="0.696197" stopColor="#6D38FB" />
          <stop offset="1" stopColor="#009DFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1668_27456"
          x1="13.8596"
          y1="5.07928"
          x2="13.8596"
          y2="9.11457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Book;
