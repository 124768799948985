import React from 'react';
import Image from 'next/image';
import cls from 'classnames';
import CloseIcon from './static/close-icon.svg';
import BerPng from './static/ber.png';
import styles from './style.module.scss';

interface InfoModalProps {
  visible: boolean;
  title: string | React.ReactNode;
  children: React.ReactNode;
  okText?: string | React.ReactNode;
  cancelText?: string | React.ReactNode;
  className?: string;
  onConfirm(): void;
  onCancel?(): void;
  cancelClassName?: string;
  showClose?: boolean;
  showCancel?: boolean;
  showBer?: boolean;
}
const InfoModal = ({
  visible,
  title,
  children,
  okText,
  cancelText,
  className,
  onConfirm,
  onCancel,
  cancelClassName,
  showClose = true,
  showCancel = true,
  showBer = false,
}: InfoModalProps) => {
  return (
    <>
      <div
        className={cls(styles.mask, {
          [styles.hidden]: !visible,
        })}
      >
        <div className={cls(styles.container, className, showBer && styles.hasBer)}>
          <section className={styles.header}>
            <div className={styles.title}>{title}</div>
          </section>
          <section className={styles.content}>{children}</section>
          <section className={styles.footer}>
            {showCancel && (
              <div role="button" className={styles.cancelBtnWrap} onClick={onCancel}>
                <span className={styles.cancelText}>{cancelText}</span>
              </div>
            )}
            <div role="button" className={styles.okBtnWrap} onClick={onConfirm}>
              <span className={styles.okText}>{okText}</span>
            </div>
          </section>
          {showClose && (
            <span role="button" className={cls(styles.close, cancelClassName)} onClick={onCancel}>
              <Image src={CloseIcon.src} width={20} height={20} alt="" />
            </span>
          )}
          {showBer && (
            <span className={styles.berWrap}>
              <Image placeholder="blur" src={BerPng} width={152} height={92} alt="" />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default InfoModal;
