import Book from '@/components/Icon/Book';
import SubjectItem from './SubjectItem';
// import Image from 'next/image';
// import Bear from '@public/imgs/pc/home/learn_situation_bear.png';
import { useTranslation } from 'next-i18next';
import PATHS from '@/utils/paths';
import Link from 'next/link';
import { FC } from 'react';
import { LearnSituationData } from './../hooks/useLearnSituation';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';

interface Props {
  data: LearnSituationData[];
}

const LearnSituation: FC<Props> = ({ data }) => {
  const { t } = useTranslation('home');
  const { traceEventWithAccount } = useTraceEventWithAccount();

  return (
    <Link href={{ pathname: PATHS.learnCenter }}>
      <div className="mb-[28px] cursor-pointer rounded-[10px] bg-[#F5F5FC] p-[12px] last:mb-0">
        <header className="flex items-center justify-between">
          <div className="flex items-center">
            <Book />
            <h3 className="ml-[6px] text-[16px] font-[600] leading-[24px] text-[#1B1F4D]">{t('学情中心-正在学习')}</h3>
          </div>
          <span
            onClick={() => {
              traceEventWithAccount({
                _event: 'U_HP_StudyCenterEnterClick',
                // subject: data?.map(({ subject }) => subject)?.join(','),
                // level1: data?.map(({ level1 }) => level1)?.join(','),
              });
            }}
            className="flex cursor-pointer items-center text-[14px] text-[#8D8FA6]"
          >
            <span>{t('学情中心-学情分析')}</span>
            <svg
              className="ml-[4px]"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <mask id="mask0_3588_44346" maskUnits="userSpaceOnUse" x="0" y="0" width="10" height="10">
                <rect width="10" height="10" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_3588_44346)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.55806 8.69185C3.31398 8.44777 3.31398 8.05204 3.55806 7.80796L6.2981 5.06792L3.55806 2.32789C3.31398 2.08381 3.31398 1.68808 3.55806 1.444C3.80214 1.19992 4.19786 1.19992 4.44194 1.444L7.62392 4.62598C7.868 4.87006 7.868 5.26579 7.62392 5.50987L4.44194 8.69185C4.19786 8.93592 3.80214 8.93592 3.55806 8.69185Z"
                  fill="#8D8FA6"
                />
              </g>
            </svg>
          </span>
        </header>
        <article className="mt-[12px] rounded-[10px] bg-[#fff]">
          <div className="px-[16px] pb-[4px] pt-[12px]">
            {data.map((item) => (
              <SubjectItem {...item} key={item.courseName} />
            ))}
          </div>
          {/* <div className="flex items-center py-[8px] pr-[12px] justify-between border-solid border-t-[1px]  border-t-[#DFE0ED]">
          <div className="flex items-center">
            <Image src={Bear} width={64} height={48} />
            <span className="ml-[12px] text-[#484C70] text-[14px] leading-[24px]">
              {t('学情中心-获得奖杯提示', { num: 100 || 0 })}
            </span>
          </div>
          <Link href={{ pathname: PATHS.learnCenter }}>
            <div className="rounded-[8px] bg-[rgba(90,110,224,0.08)] w-[28px] h-[28px] flex items-center justify-center cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                <path
                  d="M3.28125 1.28613L7.13867 5.14355L3.28125 9.00098"
                  stroke="#5A6EE0"
                  strokeWidth="1.42857"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Link>
        </div> */}
        </article>
      </div>
    </Link>
  );
};

export default LearnSituation;
