import useUser from '@/store/useUser';

const useReportForGs2User = () => {
  const { isGs2User } = useUser((store) => ({
    isGs2User: store.isGs2User,
  }));

  if (isGs2User === undefined) {
    return {};
  }

  return { studentType: isGs2User ? 'GS2.0' : '普通学员' };
};

export default useReportForGs2User;
