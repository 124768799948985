import React from 'react';
import cls from 'classnames';
import { i18n } from 'next-i18next';
import { Button } from '@/components';
import LogoIcon from '@/components/AppointmentModalClockIcon/Logo';

import styles from './style.module.scss';

interface SubscribeProps {
  bannerTitle: string;
  bannerSubTitle: string;
  cover: string;
  title: string;
  onClick: () => void;
  submitText: string;
  className?: string;
  type: string;
  btnColor?: 'yellow' | 'gray';
  typeAppointment?: 'NONE' | 'TRAILED' | 'COMPLETED';
  subject: 'ENGLISH' | 'MATH' | 'CHINESE';
  useBtn?: boolean;
  size?: 'S' | 'M' | 'L';
}

const Subscribe = (props: SubscribeProps) => {
  const {
    cover,
    title,
    onClick,
    submitText,
    className,
    type,
    btnColor,
    typeAppointment,
    bannerTitle,
    bannerSubTitle,
    subject,
    useBtn = true,
    size = 'S',
  } = props;

  const renderBtn = () => {
    if (typeAppointment === 'TRAILED') {
      return (
        <Button onClick={onClick} role="button" className={cls(styles.button, styles.trailed)}>
          {submitText}
        </Button>
      );
    }

    return useBtn ? (
      <Button shieldIcon onClick={onClick} className={cls(styles.button, styles[`button-${type}-${btnColor}`])}>
        {submitText}
      </Button>
    ) : (
      <span onClick={onClick} role="button" className={styles.noBtn}>
        {submitText}
      </span>
    );
  };
  return (
    <div
      className={cls(
        styles.subscribe,
        styles[`appointment-${typeAppointment}`],
        styles[`subscribe-${subject}`],
        styles[`subscribe-size-${size}`],
        className,
      )}
      data-expose-event="U_HP_AvailableClassesCardExpose"
      data-expose-data={JSON.stringify({
        subject,
      })}
    >
      <div className={styles.cover}>
        <div className={styles.coverInfo}>
          <LogoIcon className={styles.logo} />
          <p className={styles.bannerTitle}>{bannerTitle}</p>
          <p className={styles.bannerLine} />
          <p className={cls(styles.bannerSubTitle, i18n?.language === 'zh' && styles.zh)}>{bannerSubTitle}</p>
        </div>
        <img draggable={false} className={styles.coverImg} src={cover} alt="" />
      </div>
      <div className={styles.footer}>
        <span className={cls(styles.title, styles[`title-${type}`])}>{title}</span>
        {renderBtn()}
      </div>
    </div>
  );
};

export default Subscribe;
