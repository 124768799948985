import { getLearnSituationInfo } from '@/api/home';
import { StudentLearningSituationSimple } from '@/api/types/home';
import { useTranslation } from 'next-i18next';
import { useUpdateEffect } from 'react-use';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';
import useLearnSituationData from '@/store/useLearnSituationData';
import useUser from '@/store/useUser';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const bgColorMap = {
  MATH: 'linear-gradient(276deg, rgba(32, 201, 255, 0.80) -0.81%, #5ABAFF 43.33%, #3E73FF 100.14%)',
  数学: 'linear-gradient(276deg, rgba(32, 201, 255, 0.80) -0.81%, #5ABAFF 43.33%, #3E73FF 100.14%)',
  CHINESE: 'linear-gradient(278deg, rgba(255, 176, 83, 0.80) -3.09%, #FF5353 56.31%, #FF4F6F 100.16%)',
  中文: 'linear-gradient(278deg, rgba(255, 176, 83, 0.80) -3.09%, #FF5353 56.31%, #FF4F6F 100.16%)',
  ENGLISH: 'linear-gradient(274deg, rgba(248, 66, 186, 0.51) 0.09%, #AD5BFF 45.06%, #6C63FE 100.09%)',
  英文: 'linear-gradient(274deg, rgba(248, 66, 186, 0.51) 0.09%, #AD5BFF 45.06%, #6C63FE 100.09%)',
};

export interface LearnSituationData {
  courseName: string;
  bgColor: string;
  total: number;
  complete: number;
  subject: 'math' | 'chinese' | 'english';
  level1: string;
}

const useLearnSituation = () => {
  const { learnSituationData, setLearnSituationData } = useLearnSituationData();
  const { traceEventWithAccount } = useTraceEventWithAccount();
  const { i18n } = useTranslation('home');
  const isZh = i18n.language === 'zh';
  const { curStudent } = useUser();
  const router = useRouter();

  const getConvertData = (learnSituationInfo: StudentLearningSituationSimple[]) => {
    const result: LearnSituationData[] = learnSituationInfo.map(
      (learnSituationItem: StudentLearningSituationSimple) => {
        const { shortNameCn, shortNameEn, subject } = learnSituationItem || {};
        const { maxFinishedSectionNum, sectionTotal, level1Name, level1NameEn } =
          learnSituationItem?.level1Progress || {};
        const shortName = isZh ? shortNameCn : shortNameEn;
        const levelName = isZh ? level1Name : level1NameEn;
        return {
          courseName: shortName ? `${shortName} - ${levelName}` : levelName,
          bgColor: bgColorMap[(subject as keyof typeof bgColorMap) || 'chinese'],
          total: sectionTotal,
          complete: maxFinishedSectionNum,
          subject,
          level1: isZh ? level1Name : level1NameEn,
        };
      },
    );

    return result;
  };

  const init = async () => {
    const { studentLearningSituationSimpleList } = (await getLearnSituationInfo()) || {};
    if (!studentLearningSituationSimpleList?.length) {
      setLearnSituationData([]);
      return;
    }
    const data = getConvertData(studentLearningSituationSimpleList);
    setLearnSituationData(data);
  };

  useEffect(() => {
    if (!router.isReady || !curStudent?.uuid) {
      return;
    }
    init();

    return () => {
      setLearnSituationData([]);
    };
  }, [curStudent]);

  useUpdateEffect(() => {
    if (!learnSituationData?.length) {
      return;
    }
    traceEventWithAccount({
      _event: 'U_HP_StudyCenterEnterExpose',
      // subject: learnSituationData?.map(({ subject }) => subject)?.join(','),
      // level1: learnSituationData?.map(({ level1 }) => level1)?.join(','),
    });
  }, [learnSituationData]);

  return { learnSituationData };
};

export default useLearnSituation;
