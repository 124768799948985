import { FC } from 'react';
import { LearnSituationData } from '../hooks/useLearnSituation';

const SubjectItem: FC<LearnSituationData> = ({ courseName, bgColor, total, complete }) => {
  return (
    <div className="mb-[16px] last:mb-[12px]">
      <div className="flex items-center justify-between">
        <h3 className="text-[14px] font-[600] leading-[22px] text-[#1B1F4D]">{courseName}</h3>
        <div className="flex items-center text-[14px] font-[500]">
          <span className="leading-[22px] text-[#1B1F4D]">{complete}</span>
          <span className="mx-[4px] leading-[22px] text-[#8D8FA6]">/</span>
          <span className="leading-[22px] text-[#8D8FA6]">{total}</span>
        </div>
      </div>
      <div className="mt-[6px] h-[8px] overflow-hidden rounded-[10px] bg-[#F0F1FC]">
        <div
          className="h-full rounded-[10px]"
          style={{
            background: bgColor,
            width: `${(complete / total) * 100}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default SubjectItem;
