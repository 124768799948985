/* eslint-disable react/display-name */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';

import Button, { TypeButton } from '@/components/NewButton';

import styles from './style.module.scss';
import ParentalVerification from '../ClassPreparation/ParentalVerification';
import { getDecryptEmail, sendParentalEmail } from '@/api/home';
import useUser from '@/store/useUser';
import { traceEvent } from '@wk/wk-gatherer';
import { useUpdateEffect } from 'react-use';
import useReportForGs2User from '@/utils/hooks/useReportForGs2User';

export default () => {
  const { t } = useTranslation(['home', 'appointment']);
  const { emailStatus, account } = useUser();
  const [showVerificationModal, setShowVerificationModal] = useState<boolean>(false);
  const reportGs2Params = useReportForGs2User();

  useUpdateEffect(() => {
    if (Object.keys(reportGs2Params).length === 0) {
      return;
    }
    traceEvent({
      data: {
        _event: 'U_HP_VerificationExpose',
        ...reportGs2Params,
      },
    });
  }, [reportGs2Params]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.wrapperLeft}>
          <div>
            <div className={styles.title}>{t('home:自主化-学员中心-家长验证')}</div>
            <div className={styles.tip}>{t('home:自主化-学员中心-请进行家长验证，解锁上课功能')}</div>
          </div>
        </div>
        <div className={styles.wrapperRight}>
          <Button
            text={t('home:自主化-家长验证-去检测')}
            type={TypeButton.PRIMARY}
            className={styles['verify-btn']}
            onClick={async () => {
              traceEvent({
                data: {
                  _event: 'U_HP_SecurityNotice_ToVerifyClick',
                  ...reportGs2Params,
                },
              });
              const email = await getDecryptEmail();
              if (email && emailStatus === 'NONE') {
                await sendParentalEmail(email);
              }
              traceEvent({
                data: {
                  _event: `U_HomePage_VerificationClick`,
                  account_id: account?.uuid,
                },
              });
              setShowVerificationModal(true);
            }}
          />
        </div>
      </div>
      <div className={styles.lock}>
        <div className={styles.lockItem}>
          <img src="/imgs/preparations/lock.svg" alt="" />
          {t('appointment:自主化-课前准备')}
        </div>
        <div className={styles.lockItem}>
          <img src="/imgs/preparations/lock.svg" alt="" />
          {t('appointment:自主化-课前预习')}
        </div>
        <div className={styles.lockItem}>
          <img src="/imgs/preparations/lock.svg" alt="" />
          {t('appointment:自主化-直播上课')}
        </div>
        <div className={styles.lockItem}>
          <img src="/imgs/preparations/lock.svg" alt="" />
          {t('appointment:自主化-课后作业')}
        </div>
      </div>
      <ParentalVerification
        open={showVerificationModal}
        onClose={() => {
          setShowVerificationModal(false);
        }}
      />
    </div>
  );
};
