import { ToReserveList } from '@/api/types/home';
// import Teacher from '@/components/Icon/Teacher';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, useEffect } from 'react';
import { SubjectTypeEnum } from '../interface';
import router from 'next/router';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';
import useReportForGs2User from '@/utils/hooks/useReportForGs2User';

const subjectMap = {
  [`${SubjectTypeEnum.ENGLISH}_EN`]: 'English',
  [`${SubjectTypeEnum.CHINESE}_EN`]: 'Chinese',
  [`${SubjectTypeEnum.MATH}_EN`]: 'Math',
  [`${SubjectTypeEnum.ENGLISH}_ZH`]: '英文',
  [`${SubjectTypeEnum.CHINESE}_ZH`]: '中文',
  [`${SubjectTypeEnum.MATH}_ZH`]: '数学',
};

const courseMap = {
  PRIVATE_ZH: '私教课',
  PRIVATE_EN: 'Private Class',
  GROUP_ZH: '班课',
  GROUP_EN: 'Group Class',
};

interface Props extends ToReserveList {
  showProgress?: boolean;
}

const MyLecture: FC<Props> = ({ showProgress, data, subject, courseId, courseNameDisplay, courseEnNameDisplay }) => {
  const { t, i18n } = useTranslation('home');
  const lang = i18n.language.toLocaleUpperCase();
  const { traceEventWithAccount } = useTraceEventWithAccount();
  const reportGs2Params = useReportForGs2User();

  useEffect(() => {
    if (Object.keys(reportGs2Params).length === 0) {
      return;
    }
    traceEventWithAccount({
      _event: 'U_SelectedClass_AppointmentNowExpose',
      SelectedClass_type: data.classType === 'PRIVATE' ? '私教课' : '班课',
      subject,
      ...reportGs2Params,
    });
  }, [reportGs2Params]);

  const toReservePage = () => {
    traceEventWithAccount({
      _event: 'U_SelectedClass_AppointmentNowClick',
      subject,
      SelectedClass_type: data.classType === 'PRIVATE' ? '私教课' : '班课',
      course_id: courseId,
      ...reportGs2Params,
    });
    router.push({
      pathname: data.classType === 'PRIVATE' ? '/home/bookClass/private' : '/home/bookClass',
      query: {
        subject,
        courseType: { PRIVATE: 'private', GROUP: 'normal' }[data.classType],
      },
    });
  };

  return (
    <div className="mb-[16px] flex items-center justify-between rounded-[10px] border-[1px] border-solid border-[#EBECFA] p-[20px] last:mb-0">
      <div
        className={classNames('break-words', showProgress ? 'max-w-[calc(100%-214px)]' : 'max-w-[calc(100%-140px)]')}
      >
        <h3 className="line-clamp-1 text-[15px] font-medium leading-[24px] text-[#1B1F4D]">
          {lang === 'ZH' ? courseNameDisplay : courseEnNameDisplay}
        </h3>
        <p className="mt-[7px] line-clamp-1 flex items-center text-[12px] text-[#8D8FA6]">
          <span>{subjectMap[`${subject}_${lang}` as keyof typeof subjectMap]}</span>
          <span className="mx-[8px] h-[10px] w-[1px] bg-[#DFE0ED]" />
          <span>{courseMap[`${data.classType}_${lang}` as keyof typeof courseMap]}</span>
          {/* <span className="w-[1px] h-[10px] bg-[#DFE0ED] mx-[8px]" />
          <Teacher className="mr-[8px]" />
          <span>Meimei</span> */}
        </p>
      </div>
      {showProgress ? (
        <div className="flex w-[194px] cursor-pointer items-center justify-between">
          <div>
            <div className="h-[6px] w-[160px] rounded-[4px] bg-[rgba(90,110,224,0.08)]">
              <div className="h-[6px] rounded-[4px] bg-[#5A6EE0]" style={{ width: '50%' }} />
            </div>
            <p className="mt-[12px] flex items-center justify-between text-[12px] text-[#8D8FA6]">
              <span>{t('上课进度')}</span>
              <span className="text-[14px] text-[#484C70]">6/10</span>
            </p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M5.73975 2.58887L9.76441 6.61353C9.95807 6.80719 9.95807 7.12117 9.76441 7.31483L5.73975 11.3395"
              stroke="#484C70"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
      ) : (
        <span
          onClick={toReservePage}
          className="cursor-pointer rounded-[30px] bg-[#FF5353] px-[12px] py-[7px] text-[12px] text-[#fff]"
        >
          {t('预约上课时间')}
        </span>
      )}
    </div>
  );
};

export default MyLecture;
