import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import styles from './style.module.scss';
import { ClassType } from '@/pageComponents/CancelCourse/constants';
import { IClassType, ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import { renderFormatDate } from '@/pageComponents/CancelCourse/Step1';
import { classTypeIcon, durationIcon, nameIcon, timeIcon } from '../constants';
type IProps = {
  course?: Partial<ICourseInfo>;
};
const CourseBaseInfo = ({ course }: IProps) => {
  const { t, i18n } = useTranslation('trailCourse');
  const { t: homeT } = useTranslation('home');
  const isZh = i18n.language === 'zh';

  return (
    <>
      <div className={styles.trialCourseItemColumn}>
        <div className={cls(styles.item, styles.borderRight)}>
          <img src={timeIcon.src} alt="time" />
          <span>{renderFormatDate(course, i18n.language)}</span>
        </div>
        <div className={styles.item}>
          <img src={nameIcon.src} alt="name" />
          <span>{isZh ? course?.sectionNameDisplay : course?.sectionNameDisplayEn}</span>
        </div>
      </div>
      <div className={styles.trialCourseItemColumn}>
        <div className={cls(styles.item, styles.borderRight)}>
          <img src={classTypeIcon.src} alt="classType" />
          <span>{`${homeT(`${ClassType[course?.classType as IClassType]}`)}`}</span>
        </div>
        <div className={styles.item}>
          <img src={durationIcon.src} alt="duration" />
          <span>{`${course?.duration}${isZh ? '' : ' '}${t('home:分钟')}`}</span>
        </div>
      </div>
    </>
  );
};
export default CourseBaseInfo;
