import React from 'react';
import cls from 'classnames';

import styles from './style.module.scss';

interface Props {
  processing?: boolean;
  title?: string | React.ReactElement;
  children: React.ReactElement;
  isLast?: boolean;
}

const TimeLine = (props: Props) => {
  const { processing = false, title, children, isLast } = props;
  return (
    <section
      className={cls(styles.timeLineContainer, { [styles.processing]: processing, [styles.noPaddingBottom]: isLast })}
    >
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </section>
  );
};

export default TimeLine;
