import React from 'react';
import cls from 'classnames';
import Tooltip from 'rc-tooltip';
import defaultTeacherHeadImg from '@public/imgs/default-teacher.png';
import { useTranslation } from 'next-i18next';
import styles from './style.module.scss';
import { toDay, toHour } from '@/utils/utils';
import TimeLine from '@/components/TimeLine';

interface Props {
  showHeader?: boolean;
  startDateTime?: string;
  endDateTime?: string;
  locale?: 'zh' | 'en';
  subTitle: string;
  classType: string;
  teacherHeadImg: string;
  fullName: string;
  courseTitle: string | React.ReactElement;
  enableDivider?: boolean;
  enablePoint?: boolean;
  enableBorder?: boolean;
  operateRender?: () => React.ReactElement;
  isProcesssingClass?: boolean;
  isLast?: boolean;
}

const CourseInfo = (props: Props) => {
  const { i18n } = useTranslation('curriculum');
  const {
    showHeader = true,
    startDateTime = '',
    endDateTime = '',
    locale = 'en',
    subTitle,
    classType,
    teacherHeadImg,
    fullName = '',
    courseTitle = '',
    enableDivider = false,
    enablePoint = false,
    enableBorder = false,
    operateRender,
    isProcesssingClass,
  } = props;
  return (
    <section
      className={cls(
        styles.wrapper,
        { [styles.divider]: enableDivider },
        { [styles.point]: enablePoint },
        { [styles.border]: enableBorder },
      )}
    >
      <div className={styles.courseWrapper}>
        <TimeLine
          processing={isProcesssingClass}
          isLast
          title={
            <>
              <span className={cls(styles.hourTitle, { [styles.processingTitle]: isProcesssingClass })}>
                {toHour(startDateTime)} - {toHour(endDateTime)}
              </span>
              <span className={styles.divider}>|</span>
              {toDay(startDateTime, i18n.language)}
            </>
          }
        >
          <>
            {showHeader && (
              <div className={cls(styles.header)}>
                <span className={styles.timeContainer}>{`${toHour(startDateTime)} -  ${toHour(endDateTime)}`}</span>

                <span className={styles.dayContainer}>
                  <span className={styles.divider}>|</span>
                  {toDay(startDateTime, locale)}
                </span>
              </div>
            )}
            <div className={styles.course}>{courseTitle}</div>
            <div className={cls(styles.info, { [styles.noHeader]: !showHeader })}>
              <Tooltip placement="bottom" overlay={subTitle}>
                <span className={styles.subTitle}>{subTitle}</span>
              </Tooltip>
              <span className={styles.divider}>|</span>
              <span>{classType}</span>
              <span className={styles.divider}>|</span>
              <img className={styles.icon} src={teacherHeadImg || defaultTeacherHeadImg.src} alt="" />
              <Tooltip placement="bottom" overlay={fullName}>
                <span className={styles.name}>{fullName}</span>
              </Tooltip>
            </div>
          </>
        </TimeLine>
      </div>
      <div className={styles.operateContainer}>{operateRender?.()}</div>
    </section>
  );
};

export default CourseInfo;
