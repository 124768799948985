/* eslint-disable import/prefer-default-export */
import { isAndroidPad, isTablet, isiPad } from './utils';

export type Hidden = 'hidden' | 'webkitHidden' | 'msHidden';

export type VisibilityChange = 'visibilitychange' | 'webkitvisibilitychange' | 'msvisibilitychange';

function getSupportedProperty(): { hidden: Hidden; visibilityChange: VisibilityChange } {
  let hidden: Hidden;
  let visibilityChange: VisibilityChange;

  if ('msHidden' in document) {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if ('webkitHidden' in document) {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  } else {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  }

  return {
    hidden,
    visibilityChange,
  };
}

const pagePideList: Array<() => void> = [];
function listenPageHide(fn: () => void) {
  const { visibilityChange } = getSupportedProperty();
  if (pagePideList.length) {
    pagePideList.forEach((pagehideFn) => {
      window.removeEventListener(visibilityChange, pagehideFn);
      window.removeEventListener('pagehide', pagehideFn);
    });
  }
  pagePideList.push(fn);
  window.addEventListener(visibilityChange, fn);

  window.addEventListener('pagehide', fn);
}

export function wakeUpWukong() {
  const DEFAULT_FALLBACK_URL = `${window.location.origin}/home/downloadPage`;
  let visibility = true;

  const TIME_OUT = 3000;

  if (isiPad()) {
    window.location.href = 'WuKongClassApp://com.wukongacademy.studentPortal';
  } else if (isAndroidPad()) {
    window.location.href = 'intent://com.wukongacademy.studentportal';
  }

  const startTime = new Date().getTime();
  const timter = setTimeout(() => {
    if (!visibility || new Date().getTime() - startTime > TIME_OUT + 50) {
      return;
    }
    if (isTablet()) {
      window.location.href = DEFAULT_FALLBACK_URL;
    }
  }, TIME_OUT);

  const onPgeHide = () => {
    visibility = false;
    if (timter) {
      clearTimeout(timter);
    }
  };
  listenPageHide(onPgeHide);
}
