import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import HomeworkSvg from '@public/imgs/pc/home/entryLine/course-homework.svg';
import CourseReplaySvg from '@public/imgs/pc/home/entryLine/course-replay.svg';
import CourseEvaluateSvg from '@public/imgs/pc/home/entryLine/course-evaluate.svg';
import { traceEvent } from '@wk/wk-gatherer';
import qs from 'query-string';
// import LeftRice from '@public/imgs/pc/home/entryLine/left-rice.svg';
// import RightRice from '@public/imgs/pc/home/entryLine/right-rice.svg';
import LeftRedDot from '@public/imgs/pc/home/entryLine/left-red-dot.svg';
import RightRedDot from '@public/imgs/pc/home/entryLine/right-red-dot.svg';
import { useRouter } from 'next/router';
import cls from 'classnames';
import { toHour, toDay } from '@/utils/utils';
import styles from './index.module.scss';
import { HOMEWORK_URL } from '@/utils/env';
import PATHS from '@/utils/paths';
import { CrmReport } from '@/api/types/home';
import useModuleName from '@/store/useModule';
import { Toast } from '@/components';
import useUser from '@/store/useUser';
import ParentalVerification from '@/pageComponents/Autonomy/ClassPreparation/ParentalVerification';

type IProps = {
  crmReport: CrmReport;
  curStudent: any;
  onBeforeClick: () => boolean;
};

const LessonTest = ({ crmReport, onBeforeClick }: IProps) => {
  const { t, i18n } = useTranslation(['home']);
  const { updateModuleName } = useModuleName();
  const { isCompletedParentalVerification } = useUser();
  const [showVerificationModal, setShowVerificationModal] = useState<boolean>(false);
  const router = useRouter();

  const reportJsonData = JSON.parse(crmReport.reportJsonData) || {};

  const {
    courseScheduleId = '',
    courseSectionId = '',
    stuScheduleId = '',
    courseStartTime = '',
    courseEndTime = '',
    trialReportUrl = '',
    showHomework,
    playbackStatus,
    showCourseCommentButton,
  } = crmReport || {};

  const { subjectCategory = '', level1 = '', stuTag = '' } = reportJsonData;

  const space = i18n.language === 'en' ? ' ' : '';

  const renderSubjectCategory = () => {
    if (['KS_INITIATION', 'KL_INITIATION'].includes(subjectCategory)) {
      return `${t('进线用户链路-启蒙中文')}${space}${t('进线用户链路-系列')} ${level1}${space}${t(
        '进线用户链路-级别',
      )}`;
    }
    if (subjectCategory === 'L') {
      return `${t('进线用户链路-国际中文')}${space}${t('进线用户链路-系列')} ${level1}${space}${t(
        '进线用户链路-级别',
      )}`;
    }
    if (['G', 'GN'].includes(subjectCategory)) {
      return `${t('进线用户链路-进阶中文')}${space}${t('进线用户链路-系列')} ${level1}${space}${t(
        '进线用户链路-级别',
      )}`;
    }

    if (subjectCategory === 'MANDARIN_FOUNDATION') {
      return `${t('进线用户链路-基础汉语')}${space}${t('进线用户链路-系列')} ${level1}${space}${t(
        '进线用户链路-级别',
      )}`;
    }

    return '';
  };
  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('home:进线-已试听-课程回顾')}</p>
      <div className={styles.content}>
        <div className={styles.whiteScope}>
          <div className={styles.viewReport}>
            <div className={styles.left}>
              <p>{t('home:进线用户链路-中文测评报告')}</p>
              <p>
                {`${toHour(courseStartTime)} -  ${toHour(courseEndTime)}`}&nbsp;&nbsp;|&nbsp;&nbsp;
                {toDay(courseEndTime, i18n.language)}
              </p>
            </div>
            <div className={cls(styles.seal, styles[`seal-${i18n.language}`])} />
            <div
              role="button"
              className={styles.vrBtn}
              onClick={() => {
                if (!onBeforeClick()) {
                  updateModuleName('课程回顾出席');
                  return;
                }
                const data = {
                  _event: 'O_PC_HomeReviewClick',
                };
                traceEvent({
                  data,
                });
                window.open(trialReportUrl, '__blank');
              }}
            >
              {t('home:进线-点击查看完整评测报告')}
            </div>
          </div>
          <div className={styles.recommend}>
            <div className={styles.leftScope}>
              <div className={styles.leftScopInner}>
                <img src="/imgs/pc/home/entryLine/left-rice.svg" alt="" />
                <div className={styles.text}>
                  <p>{t(stuTag)}</p>
                  <p>{t('home:进线-表现类型')}</p>
                </div>
                <img src="/imgs/pc/home/entryLine/right-rice.svg" alt="" />
              </div>
            </div>
            <div className={styles.line} />
            <div className={styles.rightScope}>
              <div className={styles.rightScopeContent}>
                <p>{t('home:进线-通过悟空中文综合能力评定')}</p>
                <div className={styles.special}>
                  <Image src={LeftRedDot} />
                  <p className={styles.p1}>{renderSubjectCategory()}</p>
                  <Image src={RightRedDot} />
                  <div className={styles.dot} />
                  <div className={styles.dot} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          {showHomework && (
            <div
              className={styles.item}
              onClick={() => {
                if (isCompletedParentalVerification) {
                  Toast.warning(t('请进行家长验证，验证完成后解锁功能'));
                  return;
                }
                if (!onBeforeClick()) {
                  updateModuleName('课程回顾出席');
                  return;
                }
                const url = `${HOMEWORK_URL()}/${i18n.language}?${qs.stringify({
                  courseSectionId,
                  applyType: 'AFTER_CLASS',
                  courseScheduleId,
                })}`;
                router.push({
                  pathname: `/home/iexercise`,
                  query: { url, backUrl: router.asPath },
                });
              }}
            >
              <Image src={HomeworkSvg} width={28} height={28} alt="" />
              <p>{t('home:进线-课后作业')}</p>
            </div>
          )}
          {playbackStatus === 'GENERATED' && (
            <div
              className={styles.item}
              onClick={() => {
                if (isCompletedParentalVerification) {
                  setShowVerificationModal(true);
                  return;
                }
                if (!onBeforeClick()) {
                  updateModuleName('课程回顾出席');
                  return;
                }
                router.push(`${PATHS.playback}?courseScheduleId=${courseScheduleId}&stuScheduleId=${stuScheduleId}`);
              }}
            >
              <Image src={CourseReplaySvg} width={28} height={28} alt="" />
              <p>{t('home:进线-课程回放')}</p>
            </div>
          )}
          {showCourseCommentButton && (
            <div
              className={styles.item}
              onClick={() => {
                if (isCompletedParentalVerification) {
                  setShowVerificationModal(true);
                  return;
                }
                if (!onBeforeClick()) {
                  updateModuleName('课程回顾出席');
                  return;
                }
                router.push(`${PATHS.remark}?courseScheduleId=${courseScheduleId}&stuScheduleId=${stuScheduleId}`);
              }}
            >
              <Image src={CourseEvaluateSvg} width={28} height={28} alt="" />
              <p>{t('home:进线-课程评价')}</p>
            </div>
          )}
          <ParentalVerification
            open={showVerificationModal}
            onClose={() => {
              setShowVerificationModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LessonTest;
