import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Cookie from 'js-cookie';
import cls from 'classnames';
import qs from 'query-string';
import Image from 'next/image';
import IconRight from '@public/imgs/pc/icon-right.svg';
import { generateNamePrefix } from '@/utils/helpers';
import { EDU_URL, SCH_URL, TOKEN } from '@/utils/constants';
import cacheStorage from '@/utils/cacheStorage';
import { log } from '@/api/log';
import styles from '@/pages/home/style.module.scss';
import PATHS from '@/utils/paths';
import { HOMEWORK_URL } from '@/utils/env';
import NewButton from '@/components/NewButton';
import WukongAudio from '@/components/Audio';
import { HomeworkProps } from '@/pageComponents/Home/NewHomework/tyeps';
import ParentalVerification from '@/pageComponents/Autonomy/ClassPreparation/ParentalVerification';
import useUser from '@/store/useUser';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';
// import ClassPreparation from '@/pageComponents/Autonomy/ClassPreparation';

interface IProps extends HomeworkProps {
  isAttendance?: boolean;
}
const NewHomework = (props: IProps) => {
  const { t, i18n } = useTranslation('home');
  const { publish, revieweds } = props;
  const router = useRouter();
  const { traceEventWithAccount } = useTraceEventWithAccount();
  const prefixName = generateNamePrefix(`home.`);
  const { isCompletedParentalVerification } = useUser();
  const [showVerificationModal, setShowVerificationModal] = useState<boolean>(false);
  const setMainDomainToken = useCallback((tokenKey?: string) => {
    Cookie.set(tokenKey ?? TOKEN, cacheStorage.getItem(TOKEN), {
      domain: window.location.href.includes('edu.net') ? EDU_URL : SCH_URL,
      path: '/',
    });
  }, []);

  useEffect(() => {
    if (publish) {
      log(prefixName`homework_show`, { state: 'publish', subject: publish?.subject });
    }
    if (revieweds?.length) {
      revieweds.forEach((reviewed) => {
        log(prefixName`homework_show`, { state: 'reviewed', subject: reviewed?.subject });
      });
    }
  }, [publish, revieweds]);

  return (
    <>
      <div className={cls(styles.title, styles.boldTitle)}>
        <div>{t('最新作业')}</div>
        <div
          className={styles.homeworkTitleMore}
          role="button"
          onClick={() => {
            traceEventWithAccount({
              _event: 'U_LatestHomework_ViewMoreClick',
              CourseSchedule_id: publish?.courseScheduleId,
              subject: publish?.subject,
            });
            log(prefixName`homework_more`);
            router.push(`${PATHS.lessonTask}?enter=home`);
          }}
        >
          {t('查看更多')}
        </div>
      </div>
      <div className={styles.homework}>
        {publish && (
          <div className={cls(styles.homeworkItemWrap)}>
            <div
              role="button"
              onClick={() => {
                traceEventWithAccount({
                  _event: 'U_LatestHomework_ToCompleteClick',
                  CourseSchedule_id: publish?.courseScheduleId,
                  subject: publish.subject,
                });
                if (isCompletedParentalVerification) {
                  setShowVerificationModal(true);
                  return;
                }
                setMainDomainToken();
                log(prefixName`homework_state`, {
                  subject: publish.subject,
                  state: publish?.all === publish?.completed ? 'finished' : 'unfinished',
                });
                const url = `${HOMEWORK_URL()}/${i18n.language}?${qs.stringify({
                  courseSectionId: publish?.courseSectionId,
                  applyType: 'AFTER_CLASS',
                  courseScheduleId: publish?.courseScheduleId,
                })}`;
                router.push({
                  pathname: `/home/iexercise`,
                  query: { url, backUrl: router.asPath },
                });
              }}
              className={cls(styles.homeworkItem, styles.noneBorder)}
            >
              <div className={styles.homeworkLeft}>
                <span className={styles.homeworkSubTitle}>{publish.stageDisplay}</span>
                <div className={styles.homeworkTitle}>
                  <span
                    className={cls(
                      styles.homeworkStatus,
                      publish?.all === publish?.completed
                        ? styles.homeworkStatusFinished
                        : styles.homeworkStatusUnFinished,
                    )}
                  >
                    {publish?.all === publish?.completed ? t('已完成') : t('未完成')}
                  </span>
                  <span className={styles.homeworkTitleName}>{publish.sectionNameDisplay}</span>
                </div>
              </div>
              <div className={styles.homeworkRight}>
                <NewButton type="primary" text={publish?.all === publish?.completed ? t('去查看') : t('去完成')} />
              </div>
            </div>
            {/* {isAttendance && (
              <div className={styles.classPreparationWrap}>
                <ClassPreparation
                  wrapClassName={styles.customWrapClass}
                  enterClassRoomBtn={
                    <NewButton
                      type="primary"
                      text={publish?.all === publish?.completed ? t('去查看') : t('去完成')}
                      onClick={() => {
                        if (isCompletedParentalVerification) {
                          setShowVerificationModal(true);
                          return;
                        }
                      }}
                    />
                  }
                />
              </div>
            )} */}
          </div>
        )}
        {revieweds?.map?.((reviewed) => (
          <div className={cls(styles.homeworkItemWrap)} key={reviewed?.courseSectionId}>
            <div
              onClick={() => {
                if (isCompletedParentalVerification) {
                  setShowVerificationModal(true);
                  return;
                }
                setMainDomainToken();
                log(prefixName`homework_reviewed_click`);
                const url = `${HOMEWORK_URL()}/${i18n.language}?${qs.stringify({
                  courseSectionId: reviewed?.courseSectionId,
                  applyType: 'AFTER_CLASS',
                  courseScheduleId: reviewed?.courseScheduleId,
                })}`;
                router.push({
                  pathname: `/home/iexercise`,
                  query: { url, backUrl: router.asPath },
                });
              }}
              role="button"
              className={cls(styles.homeworkItem, styles.noneBorder)}
            >
              <div className={styles.homeworkLeft}>
                <span className={styles.homeworkSubTitle}>{reviewed.stageDisplay}</span>
                <div className={styles.homeworkTitle}>
                  <span className={cls(styles.homeworkStatus, styles.homeworkStatusReviewed)}>{t('已批阅')}</span>
                  <span className={styles.homeworkTitleName}>{reviewed.sectionNameDisplay}</span>
                </div>
              </div>
              <div className={styles.homeworkRight}>
                {new Array(reviewed?.star).fill('').map((_, index) => (
                  <img className={styles.homeworkStar} src="/imgs/pc/icon-star.svg" key={index} alt="" />
                ))}
                {new Array(5 - reviewed?.star).fill('').map((_, index) => (
                  <img className={styles.homeworkStar} src="/imgs/pc/icon-star-gray.svg" key={index} alt="" />
                ))}

                {reviewed.audioUrl && <WukongAudio src={reviewed.audioUrl} />}
                <span className={styles.arrow}>
                  <Image src={IconRight} />
                </span>
              </div>
            </div>
            {/* {isAttendance && !publish && index === 0 && (
              <div className={styles.classPreparationWrap}>
                <ClassPreparation wrapClassName={styles.customWrapClass} />
              </div>
            )} */}
          </div>
        ))}
      </div>
      <ParentalVerification
        open={showVerificationModal}
        onClose={() => {
          setShowVerificationModal(false);
        }}
      />
    </>
  );
};

export default NewHomework;
