import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { titleBgIcon } from '../constants';
import styles from './style.module.scss';
const TrialHeader = () => {
  const { t } = useTranslation('trailCourse');
  return (
    <div className={styles.trialHeader}>
      <span className={styles.title}>{t('我的体验课')}</span>
      <div className={styles.pic}>
        <Image src={titleBgIcon} width={152} height={82} className={styles.titleBg} alt="" />
      </div>
    </div>
  );
};
export default TrialHeader;
