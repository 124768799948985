import { LearnSituationData } from '@/pageComponents/Home/hooks/useLearnSituation';
import create from 'zustand';
import { combine } from 'zustand/middleware';

export interface LearnSituationState {
  learnSituationData: LearnSituationData[];
}

export interface LearnSituationMethod {
  setLearnSituationData: (learnSituationData: LearnSituationData[]) => void;
}

const useLearnSituationData = create(
  combine<LearnSituationState, LearnSituationMethod>({ learnSituationData: [] }, (set) => ({
    setLearnSituationData: (learnSituationData: LearnSituationData[]) => {
      set(() => ({ learnSituationData }));
    },
  })),
);

export default useLearnSituationData;
