import { forwardRef, useImperativeHandle, useState } from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import gift from '@public/imgs/trail-course/gift.png';
import close from '@public/imgs/trail-course/close.png';
import { useTranslation } from 'next-i18next';

type IProps = {
  handleHide?: () => void;
};
export type IRescheduleModal = {
  onShow: () => void;
  onHide: () => void;
};
const RescheduleModal = (_: IProps, ref: any) => {
  const { t } = useTranslation(['trailCourse']);
  const [visible, setVisible] = useState(false);
  useImperativeHandle(ref, () => ({
    onShow: () => {
      setVisible(true);
    },
    onHide: () => {
      setVisible(false);
    },
  }));
  return (
    <>
      {visible ? (
        <div className={styles.rescheduleBox}>
          <div className={styles.content}>
            <div className={styles.rectangleBox}>
              <div className={styles.left}>
                <div className={styles.giftIcon}>
                  <Image src={gift} width={32} height={32} alt="gift" />
                </div>
                <div className={styles.desc}>
                  <span className={styles.question}>{t('错过体验课')}</span>
                  <span className={styles.answer}>{t('错过体验课-描述')}</span>
                </div>
              </div>
              <div
                className={styles.right}
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Image className={styles.closeIcon} src={close} width={20} height={20} alt="close" />
              </div>
            </div>
            <div className={styles.triangleBox}>
              <svg xmlns="http://www.w3.org/2000/svg" width="121" height="9" viewBox="0 0 121 9" fill="none">
                <path
                  d="M62.9962 5.25577C61.8087 7.03697 59.1913 7.03697 58.0038 5.25577L54.5 1.04907e-06L66.5 0L62.9962 5.25577Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default forwardRef(RescheduleModal);
