import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Parser, Player as SvgaPlayer } from 'svga';
import cls from 'classnames';
import Image from 'next/image';

import IconPause from '@public/imgs/pc/audio/icon-pause.svg';
import styles from './style.module.scss';

enum PlayStatus {
  PAUSED = 'paused',
  PLAYING = 'playing',
}

interface Props {
  src: string;
}

const Audio = (props: Props) => {
  const { src } = props;
  const [playStatus, setPlayStatus] = useState<PlayStatus>(PlayStatus.PAUSED);
  const svgaRef = useRef<HTMLCanvasElement>(null);

  const initSvga = async (player: SvgaPlayer) => {
    const parser = new Parser({ isDisableWebWorker: true });
    const svga = await parser.load('/imgs/pc/audio/animate-playing.svga');
    await player?.mount(svga);
    player?.start();
  };

  const svgPlayer = useMemo(() => {
    if (svgaRef.current) {
      return new SvgaPlayer({ container: svgaRef.current });
    }
    return null;
  }, [svgaRef.current]);

  const createAudioPlayer = () => {
    const audioPlayer = document.createElement('audio');
    audioPlayer.src = src;
    audioPlayer.addEventListener('ended', () => {
      // svgPlayer?.pause();
      setPlayStatus(PlayStatus.PAUSED);
    });
    return audioPlayer;
  };

  const audioPlayer = useMemo(() => {
    return createAudioPlayer();
  }, []);

  const onPlayAudio = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (playStatus === PlayStatus.PAUSED) {
      audioPlayer.currentTime = 0;
      audioPlayer.play();
      // svgPlayer?.start();
      setPlayStatus(PlayStatus.PLAYING);
    } else {
      audioPlayer.pause();
      // svgPlayer?.pause();
      setPlayStatus(PlayStatus.PAUSED);
    }
  };

  useEffect(() => {
    if (svgPlayer) {
      initSvga(svgPlayer);
    }
    return () => {
      svgPlayer?.destroy();
    };
  }, [svgaRef.current]);

  const onPageHide = useCallback(() => {
    if (document.hidden) {
      audioPlayer?.pause();
      // svgPlayer?.pause();
      setPlayStatus(PlayStatus.PAUSED);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('visibilitychange', onPageHide);
    return () => {
      window.removeEventListener('visibilitychange', onPageHide);
      audioPlayer?.setAttribute('src', '');
    };
  }, []);

  return (
    <div role="button" className={styles.container} onClick={onPlayAudio}>
      <div className={styles.cover}>
        <Image className={cls({ [styles.hidden]: playStatus === PlayStatus.PLAYING })} src={IconPause} />
      </div>
      <canvas width={20} height={20} ref={svgaRef} />
    </div>
  );
};

export default React.memo(Audio);
