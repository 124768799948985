import dynamic from 'next/dynamic';

const VerifyModalDynamic = dynamic(
  () => {
    return import('./index');
  },
  { ssr: false },
);

export default VerifyModalDynamic;
